/* eslint-disable */
import { saveAs } from 'file-saver'
import JSZip from "jszip";
import axios from "axios";
import { IMG_URL_PRE } from "@/config";
import {
  getContractContent,
} from "@/service/contract";

function getFile(url) {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url,
      responseType: "arraybuffer",
    }).then((data) => resolve(data.data)).catch(e => {
      reject(e.message)
    });
  });
}

async function getUrl(contract_no) {
  return await getContractContent(contract_no)
}

function getSingleFolderFile(zip, folderName, folderData) {
  return new Promise((resolve) => {
    const instance = zip.folder(folderName);
    Promise.all(
      folderData.map((item) => {
        // if (item.indexOf('ht-s2c/v1/download-ht')) {
        //   return getFile(item);
        // } else {
        //   return getFile(`${IMG_URL_PRE}${item}`);
        // }
        return getFile(`${item}`);
      })
    ).then((rst) => {
      rst.forEach((fileData, index) => {
        instance.file(
          `${folderName}${index + 1}${/\.[^\.]+$/.exec(folderData[index])[0]}`,
          fileData
        );
      });
      resolve(zip);
    });
  });
}

function getMultipleFolderFile(zip, fileList) {
  return new Promise((resolve) => {
    Promise.all(
      fileList.map((fileInfo) => {
        return getSingleFolderFile(
          zip,
          fileInfo.folderName,
          fileInfo.fileData
        );
      })
    ).then((rst) => {
      resolve(rst);
    });
  });
}

// 旧版下载文件夹及文件(申请材料)
export async function export_file_to_zip(fileData = [], zipName) {
  const zip = new JSZip();
  await getMultipleFolderFile(zip, fileData)
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `${zipName}.zip`);
  });

}

// 新版下载文件夹及文件(下载申请材料 批量下载申请表共用)
export async function export_file_to_zip1(fileData = [], zipName) {
  const zip = new JSZip();

  for (let k of fileData) {
    if (k.folderName.indexOf("申请表") > -1) {
      for (let [index, item] of k.fileData.entries()) {
        const url = await getUrl(item);
        zip.file(`${k.folderName}联 ${index + 1}-2.pdf`, getFile(url))
      }
    } else {
      k.fileData.forEach((item, index) => {
        zip.file(`${k.folderName}${index + 1}${/\.[^\.]+$/.exec(item)[0]}`, getFile(`${IMG_URL_PRE}${item}`))
      })
    }

  }

  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `${zipName}.zip`);
  });

}

export async function export_folder_to_zip(fileData = [], zipName) {
  const zip = new JSZip();
  console.log("fileData",fileData);
  for (let folder of fileData) {
    for (let fileInfo of folder.folderData) {
      for (let [i, k] of fileInfo.fileData.entries()) {
        if (fileInfo.fileName.indexOf("申请表") > -1) {
          for (let [index, item] of fileInfo.fileData.entries()) {
            const url = await getUrl(item);
            zip.file(`${folder.folderName}/申请表联 ${index + 1}-2.pdf`, getFile(url))
          }
        } else if (fileInfo.fileName.indexOf("渲染图") > -1) {
          let name = k.split('/')[k.split('/').length-2];
          zip.file(`${folder.folderName}/${name}${/\.[^\.]+$/.exec(k)[0]}`, getFile(`${IMG_URL_PRE}${k}`))
        } else if (fileInfo.fileName.indexOf("设计图纸") > -1) {
          let name = k.split('/')[k.split('/').length-2];
          zip.file(`${folder.folderName}/${name}${/\.[^\.]+$/.exec(k)[0]}`, getFile(`${IMG_URL_PRE}${k}`))
        } else {
          zip.file(`${folder.folderName}/${fileInfo.fileName}${i + 1}${/\.[^\.]+$/.exec(k)[0]}`, getFile(`${IMG_URL_PRE}${k}`))
        }
      }


    }
  }



  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `${zipName}.zip`);
  });

}
